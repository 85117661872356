<template>
    <div class="pic-chart">
        <apexchart v-if="series.length" type="donut" ref="donutChart" width="144" height="144" :options="chartOptions" :series="series"></apexchart>
        <div class="user-picture">
            <img v-lazy="lazyLoadPicture" alt="applicant-img" class="img-fluid"/>
        </div>
        <div class="user-chart-detail" v-if="applicant.scores.final_score !== null">
            <ul>
                <li v-for="(label, key) in chartOptions.labels" v-if="scores[key] !== null">
                    {{ $t(label) }}: <strong :style="`color: ${chartOptions.colors[key]}`">{{ scores[key] }}%</strong>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import {AI_SCORE_COLORS} from "../../extra/constants";

import VueLazyLoad from "vue-lazyload";
import {vueJsLazyLoad as vueJsLazyLoadOptions} from "../../config/options";

Vue.use(VueLazyLoad, vueJsLazyLoadOptions);


export default {
    name: "CandidatePicture",
    props: {
        picture: {
            type: String,
            required: true
        },
        applicant: {
            type: Object,
            required: true,
        }
    },
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            scores: [null, null, null, null, null],
            series: [0, 0, 0, 0],
            chartOptions: {
                chart: {
                    type: 'donut',
                },
                labels: ["Quiz", "Skill", "Education", "Experience", "Total"],
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false,
                },
                stroke: {
                    width: 0,
                },
                colors: [AI_SCORE_COLORS.QUIZ, AI_SCORE_COLORS.SKILLS, AI_SCORE_COLORS.EDUCATION, AI_SCORE_COLORS.EXPERIENCE, AI_SCORE_COLORS.TOTAL],

                plotOptions: {
                    pie: {
                        startAngle: 0,
                        expandOnClick: false,
                        donut: {
                            size: '92%',
                            labels: {
                                show: false,
                            },
                            value: {
                                show: false,
                            }
                        },
                    }
                },
                tooltip: {
                    enabled: false,
                },
            },
        }
    },
    computed: {
        lazyLoadPicture() {
            return {
                src: this.picture,
                error: '/assets/images/placeholder/error.svg',
                loading: '/assets/images/placeholder/circle-loader.svg'
            }
        },
    },
    mounted() {
        this.scores = Object.values(this.applicant.scores) || [];
        this.series = (this.scores.slice(0, 4).map(s => Number(s)));
    }
}
</script>

<style scoped>
.pic-chart {
    position: relative;
    cursor: pointer;
}

.pic-chart .user-picture {
    position: absolute;
    top: 10px;
    left: 23px;
    width: 100px !important;
    height: 100px !important;
}

.pic-chart .user-chart-detail {
    position: absolute;
    background: #fff;
    padding: 10px 15px;
    font-size: .8em;
    border-radius: 10px;
    top: -85%;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, .1));
    width: 140px;
    display: none;
}

.pic-chart .user-chart-detail::after {
    content: '';
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    bottom: -10px;
    left: 60px;
}

.pic-chart:hover .user-chart-detail {
    display: block;
}

</style>
